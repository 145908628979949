body{
    margin: 0;
    box-sizing: border-box;
}

.home-page{
    margin-top: 20px;
    height: 100px;
    background-color: silver;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
    
}
